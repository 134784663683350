@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Avant Garde';
        font-weight: 200;
        src: url('../public/assets/fonts/AvantGardeITCbyBT-Demi.otf') format('opentype');
    }
}

* {
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
}

h1, h2 {
    font-family: 'Avant Garde', sans-serif;
}

.blob {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2000px;
    height: 2000px;
    border-radius: 100%;
    background: linear-gradient(#004DD8 20%, #1f2f3a);
    filter: blur(300px);
    transition: all 450ms ease-out;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(calc(-50% + 15px), -50%);
    z-index: 1;
    opacity: 0.8;
}

.light-gradient {
    /*background: rgb(55, 73, 229);*/
    /*background: linear-gradient(0, rgba(250, 250, 250, 1) 0%, rgba(55, 73, 229, .8) 60%, rgba(55, 73, 229, 1) 100%);*/
    /*background-position: center;*/
    background: radial-gradient(51.77% 44.98% at 50% 49%, rgba(37, 99, 235, 0.70) 0%, rgba(255, 255, 255, 0.00) 100%);
}

.dark-gradient {
    /*background: rgb(55, 73, 229);*/
    /*background: linear-gradient(0, rgba(19, 19, 22, 1) 0%, rgba(55, 73, 229, .8) 60%, rgba(55, 73, 229, 1) 100%);*/
    /*background-position: center;*/
    background: radial-gradient(51.77% 44.98% at 50% 49%, rgba(191, 219, 254, 0.70) 0%, rgba(2, 6, 23, 0.00) 100%);
}

.gray-border {
    border: solid rgba(169, 169, 169, 0.3) 1px;
}

.avatars {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.svg {
    width: 100%;
    height: 100vh;
    transform: rotate(0);
    /*animation: rotate 2s ease-in-out forwards infinite;*/
}

.stroke {
    stroke-linecap: round;
}

.path-track {
     stroke-linecap: round;
    stroke-dasharray: 175;
    stroke-width: 1px;
    stroke-dashoffset: 130;
    stroke: #4e4ac1;
    fill: none;
    animation: dash2 5s ease-in-out infinite;
}

.path {
    stroke-dasharray: 175;
    stroke-width: 3px;
    stroke-dashoffset: 0;
    stroke: #4e4ac1;
    fill: none;
    opacity: 0.5;
    animation: dash 3s ease-in-out infinite;
}

@keyframes dash2 {
    from {
        stroke-dashoffset: 130;
    }
    to {
        stroke-dashoffset: 480;
    }

}

@keyframes dash {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 350;
    }
}

@keyframes rotate {
    from {
        transform: rotate(-360deg);
    }
    to {
        transform: rotate(0);
    }
}

.dark {
    background-color: #131316;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #004DD8;
}


.company-images {
    background-image: url("assets/Culture.png");
}

.MattL {
    background-image: url("assets/TeamAvatars/MattL.png");
}

.MilesO {
    background-image: url("assets/TeamAvatars/MilesO.png");
}

.JakeS {
    background-image: url("assets/TeamAvatars/JakeS.png");
}

.JasonP {
    background-image: url("assets/TeamAvatars/JasonP.png");
}

.MorganN {
    background-image: url("assets/TeamAvatars/MorganN.png");
}

.IanT {
    background-image: url("assets/TeamAvatars/IanT.png");
}

.BrianS {
    background-image: url("assets/TeamAvatars/BrianS.png");
}

.SharonD {
    background-image: url("assets/TeamAvatars/SharonD.png");
}

.QuintonG {
    background-image: url("assets/TeamAvatars/QuintonG.png");
}

.JonathanN {
    background-image: url("assets/TeamAvatars/JonathanN.png");
}

.JustinE {
    background-image: url("assets/TeamAvatars/JustinE.png");
}

.ElviaF {
    background-image: url("assets/TeamAvatars/ElviaF.png");

}

.MichaelR {
    background-image: url("assets/TeamAvatars/MichaelR.png");
}

.DrewM {
    background-image: url("assets/TeamAvatars/DrewM.png");
}

.RyanO {
    background-image: url("assets/TeamAvatars/RyanO.png");
}


.BenA {
    background-image: url("assets/TeamAvatars/BenA.png");
}

.MattyP {
    background-image: url("assets/TeamAvatars/MattyP.png");
}

.ChrisM {
    background-image: url("assets/TeamAvatars/ChrisM.png");
}
.StephN {
    background-image: url("assets/TeamAvatars/StephN.png");
}

.CodyP {
    background-image: url("assets/TeamAvatars/CodyP.png");
}